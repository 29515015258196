import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { createRoot } from 'react-dom/client';
import { AuthProvider, authService } from 'services/auth';
import { ClientProvider } from '@customer-frontend/graphql-client';
import Pages from 'pages';
import { FeatureFlagProvider } from '@customer-frontend/feature-flags';
import { I18nProvider } from '@customer-frontend/i18n';
import { ErrorBoundary } from '@customer-frontend/services';
import { DesignSystemProvider } from '@eucalyptusvc/design-system';
import { EventServiceProvider } from '@customer-frontend/events';
import { EnvironmentProvider } from '@customer-frontend/environment';
import { OfflineWrapper } from '@customer-frontend/offline';
import { eventService } from 'services/event';
import { logger } from 'utils/logging';
import { notificationService } from '@customer-frontend/notifications';
import { utmService } from '@customer-frontend/utm';
import FigureStretch from './assets/figure-stretch.svg';
import { getConfig } from '@customer-frontend/config';
import { environment } from './environment';
import { routes } from 'utils/routes';
import { ChatProvider } from '@customer-frontend/chat-provider';
import { rum } from './utils/rum';

import './styles/tailwind.generated.css';
import './styles/chat.css';

void rum;

const config = getConfig();

notificationService.init({ brand: config.brand });
utmService.saveUtmsToCookie();

const rootElement = document.getElementById('root');
if (!rootElement) {
  throw new Error('Failed to find the root element');
}
const root = createRoot(rootElement);

root.render(
  <EnvironmentProvider {...environment}>
    <DesignSystemProvider brand={config.brand}>
      <I18nProvider>
        <ClientProvider
          apiUrl={environment.apiUrl}
          appEnv={environment.appEnv}
          logger={logger}
          getToken={() => authService.getAccessToken()}
          onUnauthenticated={() => {
            authService.clear();
            window.location.href = routes.login;
          }}
        >
          <AuthProvider logger={logger}>
            <EventServiceProvider service={eventService}>
              <FeatureFlagProvider logger={logger}>
                <ChatProvider
                  streamAppKey={environment.streamAppKey || ''}
                  logger={logger}
                >
                  <OfflineWrapper brandName="Compound" image={FigureStretch}>
                    <ErrorBoundary
                      profileRoute={routes.profile}
                      environment={environment}
                      logger={logger}
                    >
                      <Pages />
                    </ErrorBoundary>
                  </OfflineWrapper>
                </ChatProvider>
              </FeatureFlagProvider>
            </EventServiceProvider>
          </AuthProvider>
        </ClientProvider>
      </I18nProvider>
    </DesignSystemProvider>
  </EnvironmentProvider>,
);
