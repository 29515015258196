import { AuthService } from '@customer-frontend/auth';
import { getConfig } from '@customer-frontend/config';
import { environment } from 'environment';
import { logger } from 'utils/logging';

let tokenKey: string;
let userKey: string;

// allow storing credentials for each compound region in dev, so that multiple brands/regions can be run concurrently
if (!environment.isProd) {
  const config = getConfig();

  tokenKey = `${config.brand}-auth-token`;
  userKey = `${config.brand}-user`;
} else {
  tokenKey = 'compound-uk-auth-token';
  userKey = 'compound-uk-user';
}

export const authService = new AuthService(
  tokenKey,
  userKey,
  'local_storage',
  logger,
);
