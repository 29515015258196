import { useAuth } from '@customer-frontend/auth';
import { useDownloadAppBannerContext } from '@customer-frontend/services';
import { Typography } from '@eucalyptusvc/design-system';
import { ReactComponent as Avatar } from '../../../assets/avatar.svg';
import { ReactComponent as Bullet } from '../../../assets/icons/juniper-bullet.svg';
import clsx from 'clsx';
import React, { ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { routes } from 'utils/routes';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const SideBar = ({ isOpen, onClose }: Props): ReactElement | null => {
  const { logout } = useAuth();
  const { show: shouldShowDownloadBanner } = useDownloadAppBannerContext();

  const transitionClass = isOpen ? 'translate-x-0' : 'translate-x-full-shadow';
  const topClass = shouldShowDownloadBanner ? 'top-30' : 'top-18';

  return (
    <div
      className={clsx(
        topClass,
        transitionClass,
        'fixed transform transition-transform duration-500 inset-0',
      )}
    >
      {isOpen && <Backdrop onClick={onClose} />}
      <div
        className={clsx(
          'absolute top-0 bottom-0 right-0 flex flex-col bg-tertiary-cream p-6 shadow-sidebar',
          'transform transition-transform duration-500',
          transitionClass,
        )}
      >
        <UserAvatar />
        <div className="divide-y divide-primary-300 border-b border-t border-primary-300">
          <ListItem>
            <Link role="button" to={routes.profile} onClick={onClose}>
              <Typography isBold size="md">
                <FormattedMessage
                  defaultMessage="Treatment plans"
                  description="Side bar option used to navigate to the 'Treatment plans' page"
                />
              </Typography>
            </Link>
          </ListItem>
          <ListItem>
            <Link role="button" to={routes.profileAccount} onClick={onClose}>
              <Typography isBold size="md">
                <FormattedMessage
                  defaultMessage="Account settings"
                  description="Side bar option used to navigate to the 'Account settings' page"
                />
              </Typography>
            </Link>
          </ListItem>
          <ListItem>
            <button onClick={logout}>
              <Typography isBold size="md">
                <FormattedMessage
                  defaultMessage="Logout"
                  description="Side bar option used to logout from the site"
                />
              </Typography>
            </button>
          </ListItem>
        </div>
      </div>
    </div>
  );
};

const ListItem = ({
  children,
}: {
  children: React.ReactNode;
}): ReactElement => (
  <div role="presentation" className="flex items-center py-3 pr-5">
    <Bullet role="presentation" className="mr-4" />
    {children}
  </div>
);

const UserAvatar = (): ReactElement => {
  const { loggedInUser } = useAuth();
  const { formatMessage } = useIntl();
  const ariaLabel = formatMessage({
    defaultMessage: 'User avatar',
    description: "Aria label for the user's avatar image",
  });
  return (
    <div className="flex items-center mb-6">
      <Avatar aria-label={ariaLabel} />
      <span className="ml-2">
        <Typography size="medium-paragraph">
          {loggedInUser?.shortAddressableName}
        </Typography>
      </span>
    </div>
  );
};

const Backdrop = ({ onClick }: { onClick: () => void }): ReactElement => (
  <div
    className="absolute inset-0 bg-neutral-900 bg-opacity-40 md:bg-opacity-0"
    onClick={onClick}
  />
);
