import { PreferenceDialogTemplate } from '@segment/consent-manager/types/types';
import { useIntl } from 'react-intl';
import { messages } from './messages';

export const usePreferencesDialogTemplate = (): PreferenceDialogTemplate => {
  const { formatMessage } = useIntl();
  return {
    headings: {
      allowValue: formatMessage(messages.headingsAllowValue),
      categoryValue: formatMessage(messages.headingsCategoryValue),
      purposeValue: formatMessage(messages.headingsPurposeValue),
      toolsValue: formatMessage(messages.headingsToolsValue),
    },
    checkboxes: {
      noValue: formatMessage(messages.checkboxesNoValue),
      yesValue: formatMessage(messages.checkboxesYesValue),
    },
    actionButtons: {
      cancelValue: formatMessage(messages.actionButtonsCancelValue),
      saveValue: formatMessage(messages.actionButtonsSaveValue),
    },
    cancelDialogButtons: {
      cancelValue: formatMessage(messages.cancelDialogButtonsCancelValue),
      backValue: formatMessage(messages.cancelDialogButtonsBackValue),
    },
    categories: [
      {
        key: 'functional',
        name: formatMessage(messages.categoryFunctionalName),
        description: formatMessage(messages.categoryFunctionalDescription),
        example: formatMessage(messages.categoryFunctionalExample),
      },
      {
        key: 'marketing',
        name: formatMessage(messages.categoryMarketingName),
        description: formatMessage(messages.categoryMarketingDescription),
        example: formatMessage(messages.categoryMarketingExample),
      },
      {
        key: 'advertising',
        name: formatMessage(messages.categoryAdvertisingName),
        description: formatMessage(messages.categoryAdvertisingDescription),
        example: formatMessage(messages.categoryAdvertisingExample),
      },
      {
        key: 'essential',
        name: formatMessage(messages.categoryEssentialName),
        description: formatMessage(messages.categoryEssentialDescription),
        example: formatMessage(messages.categoryEssentialExample),
      },
    ],
  };
};
